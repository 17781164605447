import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Wrapper from "../components/wrapper"
import { media, TransitionMixin } from "../components/helpers"

const BannerImageContainer = styled.div`
  position: relative;
  .wrapper-class {
    left: 0;
    right: 0;
    position: absolute;
    top: 24vh;
    .content-container {
      max-width: 500px;
      margin: 0 auto;
      ${media.large`margin: 0 ;`}

      h1 {
        margin: 0 0 30px;
        color: #fff;

        ${media.large`font-size: 40px;`}
      }

      p {
        line-height: 1.5;
        color: #fff;
        margin: 0 0 30px;
        font-size: 17px;
        font-weight: 300;
      }

      .btn-wrap {
        display: flex;
        max-width: 340px;
        a {
          text-decoration: none;
          display: inline-block;
          flex: 1;
          &:first-child {
            margin-right: 20px;
          }
          img {
          }
          .gatsby-image-wrapper {
            max-width: 140px;
            height: 37px !important;
            margin: 0;
            border-radius: 20px;

            ${media.medium`max-width: 150px; `}
          }
        }
      }
    }
  }

  .desktop-image {
    display: none;
    overflow: hidden;
    ${media.large`display: block;  height: 80vh;`}
  }
  .mobile-image {
    display: block;
    ${media.large`display: none;`}
    .gatsby-image-wrapper {
      height: 80vh;
      overflow: hidden;
      ${media.large`height: 0;`}
    }
  }
`

const TridentContainer = styled.section`
  margin: 70px 0;
  .wrapper-class {
    > .title-wrap {
      text-align: center;
      margin-bottom: 60px;
      h2 {
        margin: 0;
        font-size: 24px;
        ${media.medium`font-size: 30px;`}
      }
    }
  }
  .flex-wrap {
    .trident-item-container {
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0px;
      }

      ${media.medium`flex: 1; margin-bottom: 0px;`}

      .inner-wrap {
        max-width: 90%;
        margin: 0 auto;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
        padding: 40px 0;

        .icon-wrap {
          .gatsby-image-wrapper {
            max-width: 120px;
            margin: 0 auto;
          }
        }
        .title-wrap {
          h3 {
            margin: 15px 0 10px;
            font-size: 20px;
            color: #1e1e1e;
          }

          p {
            font-weight: 300;
            color: #4d4d4d;
            margin: 0 0 25px;
            font-size: 13px;
            padding: 0 20px;
            ${media.medium`padding: 0;`}
          }
        }
        .btn-wrap {
          a {
            color: #4d4d4d;
            text-decoration: none;
            display: inline-block;
            border: 1px solid #4d4d4d;
            padding: 10px 0;
            width: 200px;
            border-radius: 20px;
            font-size: 14px;
            line-height: 1;
            ${TransitionMixin(".25s")};

            &:hover {
              color: #fff;
              background-color: #4d4d4d;
            }
          }
        }
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      apple: file(relativePath: { eq: "apple.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      android: file(relativePath: { eq: "android.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allWordpressPage(filter: { title: { eq: "Home" } }) {
        edges {
          node {
            date
            id
            title
            slug
            acf {
              banner_headline
              banner_blurb
              banner_image_desktop {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              banner_image_mobile {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              what_are_you_looking_for_section_title
              what_are_you_looking_for_section {
                title
                blurb
                button_text
                button_url
                icon {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log(data)
  const page = data.allWordpressPage.edges[0].node
  console.log(page)
  return (
    <Layout>
      <SEO title="Home" />
      <section className="banner-container home">
        <BannerImageContainer>
          <div className="banner-image desktop-image">
            <Img
              fluid={
                page.acf.banner_image_desktop.localFile.childImageSharp.fluid
              }
            ></Img>
          </div>
          <div className="banner-image mobile-image">
            <Img
              fluid={
                page.acf.banner_image_mobile.localFile.childImageSharp.fluid
              }
            />
          </div>
          <Wrapper styleClass size="md mobile-md">
            <div className="content-container">
              <h1>Is your phone dead and you're on the go?</h1>
              <p>
                Charge up with ChargeTree; we are a network of battery rental
                kiosks conveniently located at your neighborhood businesses.
              </p>
              <div className="btn-wrap">
                <a href="#">
                  <Img fluid={data.apple.childImageSharp.fluid} />
                </a>
                <a href="#">
                  <Img fluid={data.android.childImageSharp.fluid} />
                </a>
              </div>
            </div>
          </Wrapper>
        </BannerImageContainer>
      </section>

      <TridentContainer>
        <Wrapper styleClass>
          <div className="title-wrap">
            <h2>What are you looking for?</h2>
          </div>
        </Wrapper>
        <Wrapper flex>
          {page.acf.what_are_you_looking_for_section.map(item => {
            return (
              <div className="trident-item-container">
                <div className="inner-wrap">
                  <div className="icon-wrap">
                    <Img fluid={item.icon.localFile.childImageSharp.fluid} />
                  </div>
                  <div className="title-wrap">
                    <h3>{item.title}</h3>
                    <p>{item.blurb}</p>
                  </div>
                  <div className="btn-wrap">
                    <a href={item.button_url}>{item.button_text}</a>
                  </div>
                </div>
              </div>
            )
          })}
        </Wrapper>
      </TridentContainer>
    </Layout>
  )
}

export default IndexPage
